import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { ContentContainer } from "../components/containers/content-container";

// Context
import { PageContext } from "../components/context/page-context";
import { Article } from "../components/article/article";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 120px 0;

  & .tag-container {
    width: fit-content;
    margin: 0 auto 50px auto;

    & h1 {
      border: 1px solid #82a0aa;
      padding: 0 10px;

      font-size: 14px;
      line-height: 25px;

      text-transform: uppercase;

      color: #82a0aa;
      background-color: #fff;

      text-align: center;
    }
  }
`;

const Artist = ({ data, pageContext }) => {
  // const [pageContext, setPageContext] = useContext(PageContext);

  // useEffect(() => {
  //   setPageContext({
  //     pageTitle: `Tag`,
  //     pageColor: `#fff`,
  //     pageType: `tag`,
  //   });
  // }, []);

  const allItems = [
    ...data.allPrismicConversation.nodes,
    ...data.allPrismicCurating.nodes,
    ...data.allPrismicWriting.nodes,
  ];

  const content = allItems.map((item, index) => (
    <Article key={`single_tag_item_${index}`} item={item} index={index} />
  ));

  return (
    <>
      <PageSeo
        seoTitle={`${pageContext.artist} - George Vasey`}
        seoText={null}
        seoImage={null}
      />
      <Page>
        <div className="tag-container">
          <h1 className="tag">{pageContext.artist}</h1>
        </div>

        <ContentContainer>{content}</ContentContainer>
      </Page>
    </>
  );
};

export default withPrismicPreview(Artist);

export const query = graphql`
  query SingleArtist($artist: String!) {
    allPrismicConversation(
      filter: {
        data: { artist_tag: { elemMatch: { artist: { eq: $artist } } } }
      }
    ) {
      nodes {
        tags
        url
        data {
          title {
            richText
          }
          content_type {
            document {
              ... on PrismicContentType {
                id
                data {
                  content_type
                  color
                }
              }
            }
          }
        }
      }
    }
    allPrismicCurating(
      filter: {
        data: { artist_tag: { elemMatch: { artist: { eq: $artist } } } }
      }
    ) {
      nodes {
        tags
        url
        data {
          title {
            richText
          }
          content_type {
            document {
              ... on PrismicContentType {
                id
                data {
                  content_type
                  color
                }
              }
            }
          }
        }
      }
    }
    allPrismicWriting(
      filter: {
        data: { artist_tag: { elemMatch: { artist: { eq: $artist } } } }
      }
    ) {
      nodes {
        tags
        url
        data {
          title {
            richText
          }
          content_type {
            document {
              ... on PrismicContentType {
                id
                data {
                  content_type
                  color
                }
              }
            }
          }
        }
      }
    }
  }
`;
